import React from "react";
import "./EventTiles.css";
import { cdn } from "../../consts/cdn";

//images
const WebinarImage = cdn.images + "Events/Webinar-Icon.svg";
const PodcastImage = cdn.images + "Events/Podcast-Icon.svg";
const SymposiumImage = cdn.images + "Events/Symposium-Icon.svg";

function EventsTile(props) {
  return (
    <div>
      {props.banner ? (
        <div className="eventstile__tile-upcoming-past-banner">
          <h1 className="eventstile__header-desktop eventstile__header-desktop--header">
            {props.futureevent ? "Future Events" : ""}
            {props.pastevent ? "Past Events" : ""}
          </h1>
        </div>
      ) : (
        ""
      )}
      <div className="eventstile__tile">
        <div className="eventstile__tile-left">
          <div className="eventstile__tile-left-title eventstile__desktop-only">
            <h1 className="eventstile__header-desktop">{props.date}</h1>
            <br />
            <p className="eventstile__paragraph-desktop">
              {props.webinar ? "Webinar" : ""}
              {props.podcast ? "Podcast" : ""}
              {props.symposium ? "Symposium" : ""}
              {props.talk ? "Booth Talk" : ""}
              {props.conference ? `${props.conference} Conference` : ""}
            </p>
            <br />
            {props.webinar ? <img src={WebinarImage} alt="logo" /> : ""}
            {props.podcast ? <img src={PodcastImage} alt="logo" /> : ""}
            {props.symposium || props.talk || props.conference ? (
              <img src={SymposiumImage} alt="logo" />
            ) : (
              ""
            )}
          </div>
          <div className="eventstile__title-mobile eventstile__mobile-only">
            <h1 className="eventstile__header-mobile-bold">{props.date} |</h1>
            <span className="eventstile__span-mobile">
              &nbsp;{props.webinar ? "Webinar" : ""}
              {props.podcast ? "Podcast" : ""}
              {props.symposium ? "Symposium" : ""}
              {props.talk ? "Booth Talk" : ""}
              {props.conference ? `${props.conference}` : ""}
            </span>
          </div>
          {props.conference ? (
            <div className="eventstile__title-mobile eventstile__mobile-only">
              <span className="eventstile__span-mobile">Conference</span>
            </div>
          ) : null}
        </div>
        <div className="eventstile__tile-right">
          {props.conference ? (
            <>
              <p className="eventstile__tile-content conference">
                {props.day1}
              </p>
              <h3 className="eventstile__tile-right-title">{props.title1}</h3>
              <h3 className="eventstile__tile-right-title">
                {props.title1extra}
              </h3>
              <br />
              <p className="eventstile__tile-content">{props.content1}</p>
              <br />
              <hr />
              <br />
              <p className="eventstile__tile-content conference">
                {props.day2}
              </p>
              <h3 className="eventstile__tile-right-title">{props.title2}</h3>
              <br />
              <p className="eventstile__tile-content">{props.content2}</p>
              <br />
              <hr />
              <br />
            </>
          ) : (
            <>
              <h3 className="eventstile__tile-right-title">{props.title}</h3>
              <br />
              <p className="eventstile__tile-content">{props.content}</p>
              <br />
              <p className="eventstile__tile-content">{props.extra}</p>
              <br />
            </>
          )}
          {props.doctors ? (
            <p className="eventstile__paragraph">
              Featured Speakers: {props.doctors}
            </p>
          ) : null}
          <br />
          {props.eventurl ? (
            <a
              href={props.eventurl}
              className="eventstile__link"
              target="_blank"
              rel="noreferrer"
            >
              <button className="eventstile__tile-button btn">
                Watch Event
              </button>
            </a>
          ) : (
            ""
          )}
          {props.registerurl ? (
            <a
              href={props.registerurl}
              className="eventstile__link"
              target="_blank"
              rel="noreferrer"
            >
              <button className="eventstile__tile-button btn">
                Register Now
              </button>
            </a>
          ) : (
            ""
          )}
          {props.podcasturl ? (
            <a
              href={props.podcasturl}
              className="eventstile__link"
              target="_blank"
              rel="noreferrer"
            >
              <button className="eventstile__tile-button btn">
                Listen Now
              </button>
            </a>
          ) : (
            ""
          )}
          {props.comingsoonurl ? (
            <button
              className="eventstile__tile-button btn disabled"
              disabled={true}
            >
              Coming Soon
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

export default EventsTile;
