import React from "react";
import "./TestimonalQuote.css";

export const TestimonalQuote = ({ quote, name, credential }) => {
  return (
    <div className="testimonalquote">
      <div className="testimonalquote__content">
        <span className="testimonalquote__quote">{quote}</span>
      </div>
      <div className="testimonalquote__reference">
        <span className="testimonalquote__name">{name}</span>
        <span className="testimonalquote__credential">{credential}</span>
      </div>
    </div>
  );
};

export default TestimonalQuote;
