import React from "react";
import SkeletonElement from "../SkeletonElement";
import "../FindASurgeon/SkeletonDoctor.css";

const SkeletonDoctor = () => {
  return (
    <div className="surgeon_skeleton_container">
      <SkeletonElement type="surgeon-img"></SkeletonElement>
      <div className="surgeon_skeleton_info">
        <SkeletonElement type="doctor-header" />
        <SkeletonElement type="location" />
        <SkeletonElement type="address-1" />
        <SkeletonElement type="address-2" />
        <SkeletonElement type="phone-link" />
      </div>
    </div>
  );
};

export default SkeletonDoctor;
