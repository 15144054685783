import React from "react";
import "../../../App.css";
import "../../_Shared/utility.css";
import "./PrivacyPolicy.css";
import Banner from "../../Banner/Banner";
import {cdn} from '../../../consts/cdn';

//images
const BannerDesktop = cdn.images + "PrivacyPolicy/Banner-Desktop.jpg";
const BannerMobile = cdn.images + "PrivacyPolicy/Banner-Mobile.jpg";

function PrivacyPolicy() {
  return (
    <div className="privacy-policy">
      <Banner
        imgdesktop={BannerDesktop}
        imgmobile={BannerMobile}
        bold1="PRIVACY POLICY"
        color="#365B7E"
      />
      <div className="content">
        <div className="privacy-policy__disclaimer-container">
          <p className="paragraph privacy-policy__paragraph">
            This privacy policy ("Privacy Policy") was last updated on January
            1, 2020.
          </p>
          <br />
          <p className="paragraph privacy-policy__paragraph">
            This Privacy Policy is provided to explain the kind of information
            Applied Medical Resources Corporation (with its affiliated
            companies, "Applied Medical") may collect while you visit us online,
            whether through our websites, including&ensp;
            <a
              className="privacy-policy__anchor"
              href="https://www.appliedmedical.com"
            >
              www.appliedmedical.com
            </a>
            &nbsp; and other Applied Medical websites, or through other websites
            or online applications, blogs, social networks, or other online or
            wireless means via computer, mobile device, or other technology
            ("Applied Medical Sites" or "Sites"), and how Applied Medical
            maintains and uses that information. By using the Applied Medical
            Sites, you are agreeing to the terms of Applied Medical's Privacy
            Policy. Please be aware that some individual Applied Medical Sites
            may have their own or additional privacy policies and terms of use,
            which you should review before using such site, including the Terms
            of Use available at&ensp;
            <a
              className="privacy-policy__anchor"
              href="https://www.appliedmedical.com/Legal/LegalNotice"
            >
              www.appliedmedical.com/Legal/LegalNotice
            </a>
            .
          </p>
          <br />
          <p className="paragraph privacy-policy__paragraph">
            Some of the information we keep on our server is information that
            you volunteer to provide through forms and some is collected
            automatically. Unless you volunteer to submit personal data
            yourself, Applied Medical will not collect any personal information.
          </p>
          <br />
        </div>
        <div className="privacy-policy__disclaimer-container">
          <h1 className="header--primary privacy-policy__header">
            Personal information you choose to provide
          </h1>
          <p className="paragraph privacy-policy__paragraph">
            In General. While Applied Medical does not generally require that
            you provide us with personally identifiable information in order to
            visit and use the Applied Medical Sites, in order to purchase
            products or participate in some other functionality of the Applied
            Medical Sites (such as filling out a contact form, requesting price
            lists, sales contract, or catalogues; registering for training; or
            submitting ideas), we may enable you to provide or post personal
            information about yourself, including patient experiences and other
            personal medical or health information and/or information that could
            reasonably be used to contact you or to identify you personally,
            such as first and last name, email address, mailing address, fax
            number, account number, medical specialty, title, telephone number,
            and hospital/facility information (collectively, "Personal
            Information"). If you choose not to provide Personal Information or
            other information to us, you may not be able to participate in
            certain functionalities of the Applied Medical Sites. If we combine
            any demographic or other information we collect about you with
            Personal Information about you, we will treat the combined
            information as Personal Information.
          </p>
          <br />
          <p className="paragraph privacy-policy__paragraph">
            Individuals Under 13 Years of Age. The Applied Medical Sites are
            open to all individuals. We do not knowingly collect or permit the
            collection, storage or use of any Personal Information from anyone
            under 13. If you are a parent or guardian who knows or has
            discovered that your child under the age of 13 has submitted
            Personal Information or other information without your permission,
            upon request, we will promptly: (a) provide direct notice to you
            indicating what, if any, Personal Information of your child has been
            collected and how it has been used and/or disclosed; (b) remove your
            child’s Personal Information or other information from our database,
            cease the use of such information and direct any other party with
            access to such information to do the same; and (c) notify you of our
            compliance with the above.
          </p>
          <br />
          <p className="paragraph privacy-policy__paragraph">
            Consent to Processing. The Applied Medical Sites are administered in
            the United States and the Sites may be replicated to various
            locations around the world to offer better performance to you. If
            you are located in the European Union or elsewhere outside of the
            United States, please be aware that information we collect may be
            transferred to and processed in the United States. By using the
            Sites, or providing us with any information, you understand and
            expressly consent to this transfer, processing and storage of your
            information in the United States, where privacy laws may not
            necessarily be as comprehensive as those in your country.
          </p>
          <br />
        </div>
        <div className="privacy-policy__disclaimer-container">
          <h1 className="header--primary privacy-policy__header">
            Social network applications
          </h1>
          <p className="paragraph privacy-policy__paragraph">
            Functionality on the Applied Medical Sites may permit interactions
            between the Sites and third-party websites, enabling you to "like",
            "share", or otherwise post content to third-party websites or
            applications. If you choose to do so, shared information (including
            any Personal Information) may become public, including being visible
            on the Sites. The third-party social network websites or
            applications may allow us to collect and share (or the third party
            to share) information about you, including Personal Information. We
            may also receive information about you if other users of social
            network websites give us access to profiles and you are a "friend"
            or other connection, or if information about you is otherwise
            accessible through your social network or interactive webpage or
            application.
          </p>
          <br />
        </div>
        <div className="privacy-policy__disclaimer-container">
          <h1 className="header--primary privacy-policy__header">
            Information automatically collected and stored
          </h1>
          <p className="paragraph privacy-policy__paragraph">
            During your visit, Applied Medical automatically collects and stores
            the domain and host from which you access the internet, the internet
            address of the website from which you linked directly to the Applied
            Medical Sites (if applicable), the date and time you arrived at the
            Applied Medical Sites, how long you spent on the site, which pages
            you visited, your internet protocol (IP) address, the location of
            any device used to access the Applied Medical Sites, and your
            computer's operating system and browser software.
          </p>
          <br />
        </div>
        <div className="privacy-policy__disclaimer-container">
          <h1 className="header--primary privacy-policy__header">
            Cookies and other tracking technologies
          </h1>
          <p className="paragraph privacy-policy__paragraph">
            The technologies used on the Applied Medical Sites to collect Use
            Information, including Device Identification, may include:
          </p>
          <br />
          <p className="paragraph privacy-policy__paragraph">
            "Cookies" (which may be html files, Flash files or other technology)
            are small text files that help store user preferences and activity.
            A Cookie is placed on a Device when it is used to visit the Sites or
            otherwise access our online offerings. Cookies may be used for many
            purposes, such as remembering you and your preferences, tracking
            your visits to our webpages and customizing your Site experience. If
            you choose to disable cookies on your Device, some features of the
            Sites or our online offerings may not function properly.
          </p>
          <br />
          <p className="paragraph privacy-policy__paragraph">
            "Sessions" are semi-permanent interactive information interchanges
            between two or more communicating devices. A session is established
            and later taken down. An established session may involve more than
            one message in each direction. A session is typically, but not
            always, stateful, meaning that at least one of the communicating
            parts needs to save information about the session history in order
            to be able to communicate. A session is the basic requirement to
            perform connection-oriented communications. An HTTP exchange between
            a browser and a remote host may include an HTTP cookie, which
            identifies state, such as a unique session ID, information about the
            user's preferences or authorization level.
          </p>
          <br />
          <p className="paragraph privacy-policy__paragraph">
            "Web beacons" (also known as image tags, pixel tags, GIFS or web
            bugs) are small pieces of code used to collect advertising data,
            such as counting page views, promotion views or advertising
            responses. Web beacons or similar technologies may be used for a
            number of purposes, including, without limitation, to count visitors
            to the Applied Medical Sites, to monitor how users navigate the
            Sites, to count how many sent emails were actually opened or to
            determine whether content sent was actually viewed.
          </p>
          <br />
          <p className="paragraph privacy-policy__paragraph">
            An embedded script is programming code that is designed to collect
            information about your interactions with the Applied Medical Sites,
            such as the links you click on. The code is temporarily downloaded
            onto your Device from our server or a third-party service provider,
            is active only while you are connected to the Sites, and is
            deactivated or deleted thereafter.
          </p>
          <br />
        </div>
        <div className="privacy-policy__disclaimer-container">
          <h1 className="header--primary privacy-policy__header">Security</h1>
          <p className="paragraph privacy-policy__paragraph">
            Applied Medical uses commercially reasonable safeguards to help
            secure and protect your Personal Information. However, no
            transmission or storage of information can be guaranteed to be
            absolutely private and secure. Applied Medical does not ensure the
            security of any information you transmit to us, and your use of the
            Sites and provision of information to us is at your risk.
          </p>
          <br />
        </div>
        <div className="privacy-policy__disclaimer-container">
          <h1 className="header--primary privacy-policy__header">
            Use of the information you provide
          </h1>
          <p className="paragraph privacy-policy__paragraph">
            Applied Medical will use your personal information for the purpose
            for which you provided it and related administrative purposes. For
            example, our use of such personal information may include responding
            to your requests, developing records of you, your purchase or
            interest in our products, contacting you with information that might
            be of interest to you (including information about products of ours
            and others), improving your experience on the Applied Medical Sites,
            providing you with support and answering questions or other
            requests, enforcing this Privacy Policy and the Terms of Use of the
            Applied Medical Sites, protecting our rights and property, and
            complying with a law or regulation, including responding to
            government investigations, validly issued subpoenas, and court
            orders.
          </p>
          <br />
          <p className="paragraph privacy-policy__paragraph">
            Your submission of ideas, patient experiences, orders for product,
            and registration for training are governed by this Privacy Policy,
            the Terms of Use, and (as applicable) by other agreements, policies,
            and terms and conditions. Our use of the information you provide for
            these purposes is subject to those agreements, policies, and terms
            and conditions.
          </p>
          <br />
          <p className="paragraph privacy-policy__paragraph">
            In the ordinary course of business, we may share some personal
            information with third-party companies that we hire to perform
            services or functions on our behalf and consistent with this Privacy
            Policy. Such services might include providing content, fulfilling
            your requests, improving our products, content, services or
            advertising, or otherwise performing services for Applied Medical.
            Additionally, we may share your information in order to host or
            operate the Sites, for data analysis and for administrative
            purposes. In such cases, we share the minimum amount of information
            reasonably necessary for the third party to complete its function.
            In all cases, we will not authorize them to keep, disclose, or use
            your information except for the purpose of providing the services
            for which they have been contracted.
          </p>
          <br />
          <p className="paragraph privacy-policy__paragraph">
            Applied Medical will not otherwise sell, rent, publish or exchange
            your personally identifiable information to any other organization
            or entity unless you are first notified and expressly agree to it,
            or as otherwise required by law.
          </p>
          <br />
          <p className="paragraph privacy-policy__paragraph">
            Applied Medical or any of its assets, including the Applied Medical
            Sites, may be sold, or other transactions may occur in which your
            Personal Information is one of the assets in the transaction. As a
            result Applied Medical reserves the right to disclose and transfer
            user information, including Personal Information, in connection with
            any significant corporate event, such as a merger, sale,
            dissolution, or acquisition, in which such information is one of the
            business assets transferred.
          </p>
          <br />
          <p className="paragraph privacy-policy__paragraph">
            We may transfer and disclose information, including your Personal
            Information, Use Information, and Device Identification (including
            IP address), to third parties: (i) when we believe that the law
            requires it; (ii) at the request of governmental authorities
            conducting an investigation; (iii) to enforce the Terms of Use or
            other applicable policies; (iv) to respond to an emergency; (v) to
            protect the rights, property, or safety of the visitors to the Sites
            or others; or (vi) to identify users in cooperation with copyright
            owners, internet service providers, wireless service providers or
            law enforcement agencies. These transfers and disclosures may be
            done with or without notice to you.
          </p>
          <br />
        </div>
        <div className="privacy-policy__disclaimer-container">
          <h1 className="header--primary privacy-policy__header">
            Opting out of email
          </h1>
          <p className="paragraph privacy-policy__paragraph">
            If you provide us with your email address, we may send you emails
            regarding Applied Medical or the products we offer. You can choose
            to opt out of receiving email from Applied Medical at any time. If
            you wish to opt out with respect to more than one email address, you
            must provide a separate request for each email address. Every email
            sent from Applied Medical will include appropriate unsubscribe
            instructions. To opt out of all email marketing messages from us,
            you must follow the instructions provided with each email.
          </p>
          <br />
        </div>
        <div className="privacy-policy__disclaimer-container">
          <h1 className="header--primary privacy-policy__header">
            Links to other sites
          </h1>
          <p className="paragraph privacy-policy__paragraph">
            The Applied Medical Sites may contain links to other websites. Site
            content or widgets may be included on websites of third parties.
            These other websites may send their own cookies to you, and they may
            independently collect data or Personal Information. A link or other
            connection from the Applied Medical Sites to any other website does
            not indicate that Applied Medical endorses or recommends that
            website. Applied Medical provides such links and connections for
            your convenience. These other sites are neither controlled by
            Applied Medical nor subject to the terms of this Privacy Policy.
            Applied Medical is not responsible for any of the content of, or
            information collected by, those sites. We encourage you to read the
            privacy statements of all such sites you may visit.
          </p>
          <br />
          <p className="paragraph privacy-policy__paragraph">
            Applied Medical’s online career portal is hosted by iCIMS, Inc. All
            data related to job applications is submitted through iCIMS, and
            then shared with Applied Medical for the purpose of evaluating your
            qualifications for consideration for employment. The information you
            provide through iCIMS through Applied Medical’s career portal will
            be collected and processed in accordance with iCIMS’ privacy policy,
            available at:&ensp;
            <a
              className="privacy-policy__anchor"
              href="https://www.icims.com/legal/privacy-notice-services/"
            >
              https://www.icims.com/legal/privacy-notice-services/
            </a>
          </p>
          <br />
        </div>
        <div className="privacy-policy__disclaimer-container">
          <h1 className="header--primary privacy-policy__header">
            Supplemental privacy notice for California residents
          </h1>
          <p className="paragraph privacy-policy__paragraph">
            This Privacy Notice for California Residents supplements the
            information contained in Applied Medical’s Privacy Policy and
            applies if you are a California resident. Applied Medical adopts
            this notice to comply with the California Consumer Privacy Act of
            2018 (“CCPA”) and other California privacy laws. Any terms defined
            in the CCPA have the same meaning when used in this notice.
          </p>
          <br />
          <p className="paragraph privacy-policy__paragraph">
            Applied Medical collects information that identifies, relates to,
            describes, references, is capable of being associated with, or can
            reasonably be linked, directly or indirectly, with you. In
            particular, Applied Medical may have collected the following
            categories of personal information from you within the last twelve
            (12) months:
          </p>
          <br />
          <div className="privacy-policy__table-container">
            <table className="privacy-policy__table">
              <thead>
                <tr className="privacy-policy__table-row">
                  <th>Category</th>
                  <th>Example</th>
                  <th>Purpose</th>
                </tr>
              </thead>
              <tbody>
                <tr className="privacy-policy__table-row">
                  <td>Identifiers</td>
                  <td>
                    Real name, email address, postal address, phone number, fax
                    number, IP address.
                  </td>
                  <td>See: USE OF THE INFORMATION YOU PROVIDE.</td>
                </tr>
                <tr className="privacy-policy__table-row">
                  <td>Professional or employment related information</td>
                  <td>
                    Medical specialty, job title, hospital/facility information,
                    medical license number, NPI number.
                  </td>
                </tr>
                <tr className="privacy-policy__table-row">
                  <td>Internet or other electronic activity</td>
                  <td>
                    Domain and hosting information, internet address of the
                    website from which you linked directly to the Applied
                    Medical Sites, the date and time you arrived at the Applied
                    Medical Sites, how long you spent on the site, which pages
                    you visited, your computer’s operating system and browser
                    software.
                  </td>
                </tr>
                <tr className="privacy-policy__table-row">
                  <td>Geolocation data</td>
                  <td>
                    Location of the device used to access the Applied Medical
                    Sites.
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <div className="privacy-policy__disclaimer-container">
              <p className="paragraph privacy-policy__paragraph">
                Subject to a verifiable request and certain exceptions, you have
                the right as a California Resident to request disclosure of the
                following regarding your personal information that Applied
                Medical has collected, used, or disclosed in the preceding
                twelve (12) months: (a) the categories of personal information
                Applied Medical has collected about you, (b) the categories of
                sources from which the personal information was collected, (c)
                the business or commercial purpose for which Applied Medical
                collected the personal information, (d) the categories of third
                parties to whom Applied Medical disclosed the category of
                personal information, and (e) the business or commercial purpose
                for which Applied Medical disclosed the category of personal
                information. Subject to a verifiable request and certain
                exceptions, you also have the right as a California resident to
                request the deletion of any of your personal information that
                Applied Medical has collected. To request disclosure of such
                information free of charge (limited to two requests per person
                within the preceding 12-month period of each verifiable
                request), or to request deletion of such information, please
                write to us at &nbsp;
                <span className="privacy-policy__span-bold">
                  Applied Medical, 22872 Avenida Empresa, Rancho Santa
                  Margarita, California 92688, Attn: California Privacy
                </span>
                , submit your request to{" "}
                <a
                  className="privacy-policy__anchor"
                  href="mailto:AppliedMedicalPrivacyPolicy@appliedmedical.com"
                >
                  AppliedMedicalPrivacyPolicy@appliedmedical.com
                </a>{" "}
                or call our toll-free number at 1-800-840-1870. Applied Medical
                will respond to these requests within forty-five (45) days after
                proper verification. Requests that come to Applied Medical by
                other means may receive a delayed response.
              </p>
              <br />
              <p className="paragraph privacy-policy__paragraph">
                Applied Medical will not discriminate against you for exercising
                any of your privacy rights under the CCPA. Under the CCPA, you
                may designate an authorized agent, as registered with the
                California Secretary of State, to make a request on your behalf.
              </p>
              <br />
            </div>
            <div className="privacy-policy__disclaimer-container">
              <h1 className="header--primary privacy-policy__header">
                Changes to this privacy policy
              </h1>
              <p className="paragraph privacy-policy__paragraph">
                Applied Medical may at any time update this Privacy Policy. We
                will provide notice about significant changes by either sending
                a notice to the primary email address we have on file for you or
                by placing a prominent notice on the Privacy Policy webpage. Any
                changes become effective immediately upon posting. Your
                continued use of the Applied Medical Sites after any changes to
                the Privacy Policy are made will constitute your agreement to be
                bound by those changes.
              </p>
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
