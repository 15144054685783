import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { sendEventGTM } from "./GoogleAnalyticsEvents";

export const ScrollDepthTracker = () => {
  const location = useLocation();

  const thresholds = useRef([25, 50, 75, 90]);
  const trackedThresholds = useRef(new Set());

  const lastReportedScrollDepth = useRef(null);

  useEffect(() => {
    window.history.scrollRestoration = 'manual';
    trackedThresholds.current.clear();

    const handleScroll = () => {
      const scrollDepth = Math.round(
        (window.scrollY /
          (document.documentElement.scrollHeight - window.innerHeight)) *
          100
      );

      if (scrollDepth === lastReportedScrollDepth.current) {
        return;
      }

      thresholds.current.forEach((threshold) => {
        if (
          scrollDepth >= threshold &&
          !trackedThresholds.current.has(threshold)
        ) {
          trackedThresholds.current.add(threshold);
          sendEventGTM("custom-scroll", {scroll_threshold: `${threshold}%`})                    
        }
      });

      lastReportedScrollDepth.current = scrollDepth;
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [location.pathname]);

  return null;
};
