const studies = [
  //
  // added 01 october 2024
  {
    header: "AJOG Global Reports",
    date: "February 2024",
    studyurl: "https://pubmed.ncbi.nlm.nih.gov/38440153/",
    boldtext:
      "Systematic review and meta-analysis of vaginal natural orifice transluminal endoscopic surgery vs laparoscopic hysterectomy",
    doctors: "Marchand G., Masoud A., et al",
    content:
      "When evaluating the latest data, it seems that vaginal natural orifice transluminal endoscopic surgery techniques may have some advantages over conventional laparoscopic hysterectomy techniques.",
    uniqueid: "16",
  },
  // added 01 october 2024
  {
    header: "Archives of Gynecology and Obstetrics",
    date: "February 2024",
    studyurl: "https://pubmed.ncbi.nlm.nih.gov/37880384/",
    boldtext:
      "vNOTES (vaginal natural orifice transluminal surgery) gynecologic procedures in morbidly and super-morbidly obese women: five year experience.",
    doctors: "Burnett A., Pitman T., Baekelandt J.",
    content:
      "vNOTES approach is feasible in morbidly obese women and may have distinct advantages over conventional laparoscopic, vaginal or open techniques.",
    uniqueid: "17",
  },
  // added 01 october 2024
  {
    header:
      "European Journal of Obstetrics & Gynecology and Reproductive Biology: X",
    date: "December 2023",
    studyurl: "https://pubmed.ncbi.nlm.nih.gov/38035122/",
    boldtext:
      "Transvaginal natural orifice transluminal endoscopic surgery (vNOTES) in gynecological emergencies",
    doctors: "Ferro R., Hurni Y., Seidler S., Huber D.",
    content:
      "Associated with data reported in the available literature, our results suggest potential benefits in treating gynecological emergencies by vNOTES over conventional laparoscopy.",
    uniqueid: "18",
  },
  // added 01 october 2024
  {
    header: "Journal of Minimally Invasive Gynecology",
    date: "July 2023",
    studyurl: "https://pubmed.ncbi.nlm.nih.gov/36966920/",
    boldtext:
      "Cumulative Sum Analysis of Learning Curve Process for Vaginal Natural Orifice Transluminal Endoscopic Surgery Hysterectomy",
    doctors: "Mereu L., Pecorino B., et al",
    content:
      "vNOTES hysterectomy is a feasible and reproducible approach for benign indications with a short learning curve and low rate of perioperative complications.",
    uniqueid: "19",
  },
  {
    header: "Journal of Minimally Invasive Gynecology",
    date: "June 2023",
    studyurl: "https://pubmed.ncbi.nlm.nih.gov/37422053/",
    boldtext:
      "Comparing vNOTES Hysterectomy with Laparoscopic Hysterectomy for Large Uteri",
    doctors: "Kheirbek N., Delporte, V. et al",
    content:
      "Our results show that in the case of hysterectomy for large uteri (>280g), vNOTES approach seems to be associated with a reduced operative time, reduced length of stay, and a higher frequency of surgery in an ambulatory setting than standard laparoscopic hysterectomy. These conclusions were also found in the subgroup analysis for patients with uteri weighing > 500g.",
    uniqueid: "1",
  },
  {
    header: "Gynecologic and Obstetric Investigation",
    date: "May 2023",
    studyurl: "https://pubmed.ncbi.nlm.nih.gov/37231836/",
    boldtext:
      "Hysterectomy Using Vaginal Natural Orifice Transluminal Endoscopic Surgery Compared with Classic Laparoscopic Hysterectomy: A New Advantageous Approach? A Systematic Review on Surgical Outcomes",
    doctors: "Chaccour C., Giannini A. et al",
    content:
      "While the feasibility and safety of the vNOTES hysterectomy were established, this review also underlines the non-inferiority of this technique when compared to laparoscopic hysterectomy in terms of surgical outcomes. In addition, vNOTES hysterectomy was associated with faster operating time, shorter hospital stay, and better postoperative pain scores compared with laparoscopic hysterectomy.",
    uniqueid: "2",
  },
  {
    header: "Archives of Gynecology and Obstetrics",
    date: "April 2023",
    studyurl: "https://pubmed.ncbi.nlm.nih.gov/36445449/",
    boldtext:
      'Implementing the transvaginal natural orifice transluminal endoscopic surgery (vNOTES) "first" strategy in benign gynecological surgeries',
    doctors: "Tekin A., Yassa M. et al",
    content:
      "Implementing the vNOTES technique as an initial approach for all benign gynecological surgeries seems feasible, safe and satisfactory, even in those with a non-prolapsed or enlarged uterus and those that have previously undergone abdominal surgery.",
    uniqueid: "3",
  },
  {
    header: "Minimally Invasive Therapy and Allied Technologies",
    date: "June 2022",
    studyurl: "https://pubmed.ncbi.nlm.nih.gov/35073493/",
    boldtext:
      "Comparison of vNOTES technique with conventional laparoscopy in gynecological emergency cases",
    doctors: "Karakas S., Kaya C. et al",
    content:
      "Our study revealed that vNOTES is a feasible approach associated with a significantly shorter duration of surgery, shorter hospital stay, lower VAS scores at six and 12h postoperatively, and less need of analgesic administration, allowing a better recovery period than conventional laparoscopy in the management of gynecological emergencies.",
    uniqueid: "4",
  },
  // added 01 october 2024
  {
    header: "Journal of Minimally Invasive Gynecology",
    date: "May 2022",
    studyurl: "https://pubmed.ncbi.nlm.nih.gov/35074513/",
    boldtext:
      "Is vNOTES Hysterectomy as Safe and Feasible as Outpatient Surgery Compared with Vaginal Hysterectomy?",
    doctors: "Merlier M., Collinet P., et al",
    content:
      "The success rate of outpatient management was 77% in the vNOTES group versus 75% in the VH group (p = .85). There was no difference in surgical outcomes between the two groups, except for the rate of salpingectomies or adnexectomies, which was significantly higher in the vNOTES group, with 100% of patients undergoing one of these procedures, compared with 60% of patients in the vaginal route group (p < .001).",
    uniqueid: "20",
  },
  {
    header: "Journal of Minimally Invasive Gynecology",
    date: "February 2022",
    studyurl: "https://pubmed.ncbi.nlm.nih.gov/34411729/",
    boldtext:
      "The Comparison of Transvaginal Natural Orifice Transluminal Endoscopic Surgery and Conventional Laparoscopy in Opportunistic Bilateral Salpingectomy for Permanent Female Sterilization",
    doctors: "Yassa M., Kaya C., et al",
    content:
      "Women seeking permanent sterilization and who underwent bilateral salpingectomy for ovarian cancer risk reduction purposes had higher satisfaction, less postoperative pain, lower analgesic requirement, and similar surgical outcomes in the vNOTES group than the conventional laparoscopy group, even in patients with previous surgeries and a nonprolapsed uterus. vNOTES approach could be the preferred method for the opportunistic bilateral salpingectomy in female sterilization.",
    uniqueid: "5",
  },
  {
    header: "Journal of the Society of Laparoscopic & Robotic Surgeons",
    date: "January 2022",
    studyurl: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC9913063/",
    boldtext:
      "Vaginal Natural Orifice Transluminal Endoscopic Surgery Revolution: The Next Frontier in Gynecologic Minimally Invasive Surgery",
    doctors: "Lerner V., May G., Iglesia C.",
    content:
      "We suspect that with vNOTES, the vaginal route can be easily attained in 30-50% of all hysterectomies across different skill sets and practice settings. … It is our prediction and hope that vNOTES will bring vaginal surgery back and lead to future innovation and advances.",
    uniqueid: "6",
  },
  {
    header:
      "European Journal of Obstetrics \u0026 Gynecology and Reproductive Biology",
    date: "August 2021",
    studyurl: "https://pubmed.ncbi.nlm.nih.gov/34237485/",
    boldtext:
      "Consensus on safe implementation of vaginal natural orifice transluminal endoscopic surgery (vNOTES)",
    doctors:
      "Kapurubandara S, Lowenstein L, Salvay H, Herijgers A, King J, Baekelandt J.",
    content:
      "An international expert based vNOTES statement is presented here to help guide adoption of vNOTES based on the experience of early adopters. Consensus was achieved on most components of this consensus statement. Given the recency of this technique, until high-level evidence becomes available, this statement provides an appropriate guidance to the safe implementation of vNOTES into gynaecological practice.",
    uniqueid: "7",
  },
  {
    header: "British Journal of Obstetrics and Gynaecology",
    date: "July 2021",
    studyurl:
      "https://obgyn.onlinelibrary.wiley.com/doi/10.1111/1471-0528.16838",
    boldtext:
      "Adnexectomy by vaginal natural orifice transluminal endoscopic surgery versus laparoscopy: results of a first randomised controlled trial (NOTABLE\xa0trial)",
    doctors: "Baekelandt J, De Mulder PA, Le Roy I, et al.",
    content:
      "vNOTES is non-inferior to laparoscopy for a successful adnexectomy without conversion. vNOTES allowed shorter operating times and less postoperative pain but there was a trend for more adverse events.",
    uniqueid: "8",
  },
  {
    header: "Journal of Investigative Surgery",
    date: "May 2021",
    studyurl: "https://doi.org/10.1080/08941939.2021.1927262",
    boldtext:
      "The comparison of surgical outcomes following laparoscopic hysterectomy and vNOTES hysterectomy in obese patients",
    doctors: "Kaya C, Yıldız Ş, Alay İ, Aslan Ö, Aydıner İE, Yaşar\xa0L.",
    content:
      "vNOTES is a feasible technique in obese women who require a hysterectomy and provides favorable outcomes considering the shorter duration of surgery and postoperative hospitalization and lower pain scores.",
    uniqueid: "9",
  },
  {
    header:
      "European Journal of Obstetrics \u0026 Gynecology and Reproductive Biology",
    date: "April 2021",
    studyurl: "https://doi.org/10.1016/j.ejogrb.2021.03.040",
    boldtext:
      "Transvaginal natural orifice transluminal endoscopic versus conventional vaginal hysterectomy with uterosacral ligament suspension for apical compartment prolapse",
    doctors:
      "Aharoni S, Matanes E, Lauterbach R, Mor O, Weiner Z, Lowenstein L.",
    content:
      "vNOTES hysterectomy with USLS is associated with reduced incidence of intraoperative complications, shorter surgical and anesthesia time, and slightly longer hospital stay, compared to the traditional vaginal surgical approach. Post-operative complications during hospital stay were similar and infrequent following the two procedures.",
    uniqueid: "10",
  },
  {
    header: "Journal of Clinical Medicine",
    date: "December 2020",
    studyurl: "https://doi.org/10.3390/jcm9123959",
    boldtext:
      "Systematic review and meta-analysis on hysterectomy by vaginal natural orifice transluminal endoscopic surgery (vNOTES) compared to laparoscopic hysterectomy for benign indications",
    doctors: "Housmans S, Noori N, Kapurubandara S, et\xa0al.",
    content:
      "Our search yielded one RCT and five retrospective cohort trials. Pooled analysis of two subgroups showed that, compared to conventional laparoscopy, vNOTES is equally effective to successfully remove the uterus in individuals meeting the inclusion criteria. vNOTES had significantly lower values for operation time, length of stay and estimated blood loss. There was no significant difference in intra- and postoperative complications, readmission, pain scores at 24h postoperative and change in hemoglobin (Hb) on day 1 postoperative.",
    uniqueid: "11",
  },
  {
    header:
      "European Journal of Obstetrics \u0026 Gynecology and Reproductive Biology",
    date: "October 2020",
    studyurl: "https://pubmed.ncbi.nlm.nih.gov/33248377/",
    boldtext:
      "Benign gynaecological procedures by vaginal natural orifice transluminal endoscopic surgery (vNOTES): complication data from a series of 1000 patients",
    doctors: "Baekelandt J, Kapurubandara S.",
    content:
      "The perioperative outcomes of this large vNOTES case series for benign gynaecological indications is comparable to other vNOTES procedures reported in the literature apart from the risk of cystotomy which is a hysterectomy specific risk. The outcomes are that of a single high-volume surgeon incorporating cases within his respective learning curve of the surgical technique and as such should be interpreted accordingly. Since 2015 the International NOTES Society has initiated a prospective complication database where all vNOTES surgeons are invited to register their cases.",
    uniqueid: "12",
  },
  {
    header: "Journal of Minimally Invasive Gynecology",
    date: "October 2020",
    studyurl: "https://doi.org/10.1016/j.jmig.2020.10.003",
    boldtext:
      "vNOTES hysterectomy for large uteri: a retrospective cohort study of 114\xa0patients",
    doctors: "Nulens K, Bosteels J, De Rop C, Baekelandt\xa0J.",
    content:
      "The vNOTES technique can offer a safe and effective alternative to laparoscopy or laparotomy in cases with a large to very large uterus, even if the patient has a history of cesarean section, obesity, or nulliparity. In 99% of all women in this study, hysterectomy was successfully performed through vNOTES without conversion. By making use of the advantages of endoscopic surgery, vNOTES might broaden the indications of vaginal hysterectomy. Randomized controlled trials are needed to evaluate whether vaginally assisted NOTES hysterectomy is superior to laparoscopic or abdominal hysterectomy in large uteri cases.",
    uniqueid: "13",
  },
  {
    header: "British Journal of Obstetrics and Gynaecology",
    date: "January 2019",
    studyurl: "https://doi.org/10.1111/1471-0528.15504",
    boldtext:
      "Hysterectomy by transvaginal natural orifice transluminal endoscopic surgery versus laparoscopy as a day-care procedure: a randomised controlled trial",
    doctors: "Baekelandt J, De Mulder PA, Le Roy I, et al.",
    content:
      "vNOTES is non-inferior to TLH for successfully performing hysterectomy without conversion. Compared with TLH, vNOTES may allow more women to be treated in a day-care setting.",
    uniqueid: "14",
  },
  {
    header: "Journal of Minimally Invasive Gynecology",
    date: "September 2014",
    studyurl: "https://pubmed.ncbi.nlm.nih.gov/24681063/",
    boldtext:
      "Hysterectomy by transvaginal natural orifice transluminal endoscopic surgery (NOTES): a series of 137 patients",
    doctors: "Lee CL, Wu KY, Su H, Wu PJ, Han CM, Yen\xa0CF.",
    content:
      "Transvaginal NOTES is a feasible technique for performance of hysterectomy and can be used in procedures that are difficult to complete via conventional vaginal surgery because posterior colpotomy is achievable. This procedure was not impeded by uterine volume, and had the advantage of no abdominal incision.",
    uniqueid: "15",
  },
];

export default studies;
