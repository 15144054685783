import { cdn } from "../../consts/cdn";

// Images
const slideImage1 = cdn.images + "Patients/Image3b.svg";
const slideImage2 = cdn.images + "Patients/Image3a.svg";
const slideImage3 = cdn.images + "Patients/Image3c.svg";
const slideImage4 = cdn.images + "Patients/Image3d.svg";
const slideImage5 = cdn.images + "Patients/Image3e.svg";
const slideImage6 = cdn.images + "Patients/Image5a.svg";
const slideImage7 = cdn.images + "Patients/Image5b.svg";
const slideImage8 = cdn.images + "Patients/Image5c.svg";

export const slide1 = [
    {
        img: slideImage1,
        description: "No Visible Scars",
        id: 1
    },
    {
        img: slideImage2,
        description: "Shorter Hospital Stay",
        id: 2
    },
    {
        img: slideImage3,
        description: "Less Postoperative Pain",
        id: 3
    },
    {
        img: slideImage4,
        description: "Faster Recovery Time",
        id: 4
    },
    {
        img: slideImage5,
        description: "Less Pain Medication",
        id: 5
    },
];

export const slide2 = [
    {
        img: slideImage6,
        title: "Before the Procedure",
        description: "Your doctor will inform you of any pre-surgery testing and provide information on eating, drinking and taking medications.",
        id: 6

    },
    {
        img: slideImage7,
        title: "During the Procedure",
        description: "After being put under general anesthesia, a small incision will be made in the vagina to place the vNOTES access platform.",
        description2: "Your doctor will then operate by inserting a camera and instruments through the platform.",
        id: 7

    },
    {
        img: slideImage8,
        title: "After the Procedure",
        description: "You may be able to go home the day of your surgery.",
        description2: "You will have a follow-up appointment a few weeks after your surgery.",
        id: 8

    },

]