import "./App.css";
import React from "react";
import Navbar from "./components/Navbar/Navbar";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Patients from "./components/Pages/Patients/Patients";
import Home from "./components/Pages/Home/Home";
import Footer from "./components/Footer/Footer";
import PatientTestimonials from "./components/Pages/Testimonials/Patients/Patient-testimonials";
import HCPTestimonials from "./components/Pages/Testimonials/HCP/HCP-testimonials";
import ClinicalStudies from "./components/Pages/HealthcareProviders/ClinicalStudies/ClinicalStudies";
import FindSurgeon from "./components/Pages/Patients/FindSurgeon/FindSurgeon";
import ContactUs from "./components/Pages/ContactUs/ContactUs";
import Redirect from "./components/Pages/Redirect/Redirect";
import PrivacyPolicy from "./components/Pages/PrivacyPolicy/PrivacyPolicy";
import Terms from "./components/Pages/Terms/Terms";
import Events from "./components/Pages/HealthcareProviders/Events/Events";
import HealthcareProviders from "./components/Pages/HealthcareProviders/HealthcareProviders";
import NotFound from "./components/Pages/NotFound/NotFound";
import Modal from "./components/Modal/Modal";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import SEO from "./components/SEO";
import { HelmetProvider } from "react-helmet-async";
import { ScrollDepthTracker } from "./utilities/ScrollDepthTracker";
// import ComingSoon from "./components/Pages/ComingSoon/ComingSoon";

function App() {
  return (
    <HelmetProvider>
      <SEO />
      <Router>
        <Navbar />
        <ScrollDepthTracker />
        {/* <ComingSoon /> */}
        <Modal />
        <ScrollToTop />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/clinicalevidence" exact component={ClinicalStudies} />
          {/* adding direct link route for marketing */}
          <Route path="/clinicalevidence/:ref" component={ClinicalStudies} />
          <Route path="/events" exact component={Events} />
          <Route
            path="/healthcareprofessionals"
            exact
            component={HealthcareProviders}
          />
          {/* adding direct link route for marketing */}
          <Route
            path="/healthcareprofessionals/:ref"
            component={HealthcareProviders}
          />
          <Route path="/patients" exact component={Patients} />
          <Route
            path="/patientstestimonials"
            exact
            component={PatientTestimonials}
          />
          <Route path="/hcptestimonials" exact component={HCPTestimonials} />
          <Route path="/findasurgeon" exact component={FindSurgeon} />
          <Route path="/contactus" exact component={ContactUs} />
          <Route path="/redirect" exact component={Redirect} />
          <Route path="/privacypolicy" exact component={PrivacyPolicy} />
          {/* legal notice path should be removed as route? */}
          <Route path="/legalnotice" exact component={Terms} />
          <Route path="*" exact component={NotFound} />
        </Switch>
        <Footer />
      </Router>
    </HelmetProvider>
  );
}

export default App;
