import React from "react";
import "./Card.css";
import { sendEventGTM } from "../../utilities/GoogleAnalyticsEvents";

const sendSurgeonLinkClickedGAEvent = (props) => { 

  const surgeon = props.middleInitial? `${props.firstName} ${props.middleInitial} ${props.lastName}` : `${props.firstName} ${props.lastName}`;
  
  sendEventGTM("surgeon-link-clicked", {name: surgeon}); 

}

function Card(props) {
  return (
    <div className="card__container">
      <div className="card__image-container">
        <img src={props.img} alt="surgeon profile" className="card__image" />

        {props.distance > 0 && (
          <div className="distance-overlay">{`${props.distance} miles away`}</div>
        )}
      </div>
      <div className="card__body">
        {Object.is(props.middleInitial, null) ? (
          <h2 className="card__title--1">
            {`${props.firstName} ${props.lastName}, `}
            <span className="card__title--2">{props.title}</span>
          </h2>
        ) : (
          <h2 className="card__title--1">
            {`${props.firstName} ${props.middleInitial} ${props.lastName}, `}
            <span className="card__title--2">{props.title}</span>
          </h2>
        )}

        <div className="card__description">
          <p className="card__location">{props.location}</p>
          <p className="card__location">{props.location2}</p>
          <br />
          <p className="card__address">
            {props.address1}
            <span className="card__additional">{props.additional}</span>
          </p>
          <p className="card__address">{`${props.city}, ${props.state} ${props.zip}`}</p>
          <br />
          <p className="card__phone">
            {props.phone} | &nbsp;
            <a
              className="card__link"
              href={props.href}
              target="_blank"
              rel="noreferrer"
              onClick={() => sendSurgeonLinkClickedGAEvent(props)}
            >
              View
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Card;
