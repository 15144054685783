const api_urls = {
    staging: "https://vnotesapidev.azurewebsites.net/api",
    prod: "https://vnotesapi.azurewebsites.net/api"
};

const getAPI = () => {
    const env = process.env.REACT_APP_ENVIRONMENT || 'staging'; 
    return api_urls[env];
};

export const api = getAPI();