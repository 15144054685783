import React from "react";
import { Link } from "react-router-dom";
import "./Button.css";

export const Button = ({ children, type, route, width, id }) => {
  const styles = {
    width: width,
  };

  return (
    <Link to={route} className="btn-link" id={id}>
      <button style={styles} className="btn" type={type} >
        {children}
      </button>
    </Link>
  );
};
