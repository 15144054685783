
// Send event data to Google Analytics
export const sendEventGA = (eventName, eventParams) => {
  window.gtag('event', eventName, {
    ...eventParams,
  });
}

// Send event data to Google Tag Manager
export const sendEventGTM = (eventName, eventParams) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: eventName,
      ...eventParams,
    });
  };


