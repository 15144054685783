const cdn_urls = {
    staging: {
        images: "https://vnotes-cdn-dev.azureedge.net/images/",
        videos: "https://vnotes-cdn-dev.azureedge.net/videos/"
    },
    prod: {
        images: "https://vnotes-cdn-prd.azureedge.net/images/",
        videos: "https://vnotes-cdn-prd.azureedge.net/videos/"
    }
};

const getCDN = () => {
    const env = process.env.REACT_APP_ENVIRONMENT || 'staging'; 
    return cdn_urls[env];
};

export const cdn = getCDN();