import React, {useState, useEffect, useRef} from "react";
import { cdn } from "../../consts/cdn";
import "./Carousel.css";
import { sendEventGTM } from "../../utilities/GoogleAnalyticsEvents";

// Images
const LeftArrow = cdn.images + "Patients/LeftArrow.svg";
const RightArrow = cdn.images + "Patients/RightArrow.svg";


function Carousel (props) {
    const data = props.data;
    const carousel_name = props.carousel_name;    
    const [slide, setSlide] = useState(0);
    const [rightSlide, setRightSlide] = useState(1);
    const [leftSlide, setLeftSlide] = useState(data.length - 1);
    const intervalRef = useRef(null);
    const nextSlideRef = useRef(null);    

    const nextSlide = () => {
        setSlide(slide === data.length - 1 ? 0 : slide + 1);
        if (!props.singleImage) {
        setLeftSlide(leftSlide === data.length - 1 ? 0 : leftSlide + 1);
        setRightSlide(rightSlide === data.length - 1 ? 0 : rightSlide + 1);
        }
    }


  // using a reference will remove missing dependency error message in useEffect for nextSlide
    nextSlideRef.current = nextSlide;


    const previousSlide = () => {
        setSlide(slide === 0 ? data.length - 1 : slide - 1);
        if (!props.singleImage) {
        setLeftSlide(leftSlide === 0 ? data.length - 1 : leftSlide - 1);
        setRightSlide(rightSlide === 0 ? data.length - 1 : rightSlide - 1);
        }
    }


    const resetSlide = () => {
        if (intervalRef.current) {
        clearInterval(intervalRef.current);
        }
    }   

    const sendCarouselGAEvent = (event) => {
      var arrow_direction;
      const arrowClassList = event.target.classList.value;     

      if (arrowClassList.includes("arrow--left")) {
        arrow_direction = "left";
      }
      else if (arrowClassList.includes("arrow--right")) {
        arrow_direction = "right";
      }
      
      sendEventGTM("carousel-navigation", {element_name: carousel_name, direction: arrow_direction});     

    }


    useEffect(() => {
        if (props.autoplay) {
          intervalRef.current = setInterval(nextSlideRef.current, 3000);
        }
        return () => {
        resetSlide();
        };
    });




    return (
      <>
      {props.singleImage ? 
      <>
        <div className="carousel carousel--1">
            <button onClick={previousSlide} className="carousel__button">
            <img src={LeftArrow} className="arrow arrow--left arrow--left--1" onClick={sendCarouselGAEvent} alt="Left arrow"/>
            </button>
            {data.map((item, index) => {
                return (
                  <div className={slide === index ? "slide" : "slide slide--hidden"} key={item.id}>
                    <div className="slide--content slide--content--1">
                      <div className="slide--content-image">
                      <img src={item.img} alt={item.title} />
                      </div>
                      <br />
                      <div className="slide--content-text">
                        <p className="patients__item-header">{item.title}</p>
                        <p className="paragraph">{item.description}</p>
                        <br/>
                        <p className="paragraph">{item.description2}</p>
                      </div>
                    </div>
                  
                  </div>
                );
            })}
            <button onClick={nextSlide} className="carousel__button">
            <img src={RightArrow} className="arrow arrow--right arrow--right--1" onClick={sendCarouselGAEvent} alt="Right arrow"/>
            </button>
        </div>
      </>
      : 
      <>
        <div className="carousel carousel--3D">
            <button className="carousel__button" onClick={previousSlide}>
            <img src={LeftArrow} className="arrow arrow--left" onClick={sendCarouselGAEvent} alt="Left arrow"/>
            </button>
             <div className="slide">
                    <div className="slide--content">
                      <img src={data[leftSlide].img} alt={data[leftSlide].description} />
                    </div>
              </div>

              <div className="slide">
                    <div className="slide--content">
                      <img src={data[slide].img} alt={data[slide].description} />
                      <p className="carousel__description">{data[slide].description}</p>
                    </div>
              </div>

              <div className="slide">
                    <div className="slide--content">
                      <img src={data[rightSlide].img} alt={data[rightSlide].description} />
                    </div>
              </div>

            <button className="carousel__button" onClick={nextSlide}>
            <img src={RightArrow} className="arrow arrow--right" onClick={sendCarouselGAEvent} alt="Right arrow"/>
            </button>
        </div>
        <span className="indicators">
        {data.map((_, index) => {
          return <button key={index} className={slide === index ? "indicator" : "indicator indicator--inactive"}></button>
        })}
      </span>
      </>
      }
      </>
    );
}

export default Carousel;