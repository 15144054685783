import React from "react";
import JWPlayer from "@jwplayer/jwplayer-react";
import "./JwPlayer.css";

export const JwPlayer = ({ file, image, title, autostart }) => {
  return (
    <JWPlayer
      file={file}
      library="https://cdn.jwplayer.com/libraries/Mw4yQI3Z.js"
      config={{
        height: "auto",
        width: "100%",
        image: image,
        autostart: autostart,
        preload: "auto",
        primary: "html5",
        androidhls: "true",
        title: title,
        ga: { label: "title", sendEnhancedEvents: true },
      }}
    />
  );
};

export default JwPlayer;
