import React from "react";
import "./Footer.css";
import { NavLink } from "react-router-dom";

function Footer() {

  const currentYear = new Date().getFullYear();

  return (
    <>
      <div className="footer-container">
        <div className="footer-link">
          <span className="border">
            <NavLink to="/privacypolicy">Privacy Policy</NavLink>
          </span>
          <span className="hide-mobile"> | </span>
          <span className="border">
            <NavLink to="/legalnotice">Legal Notice</NavLink>
          </span>
          <span className="hide-mobile"> | </span>
          <span>
            <NavLink to="/contactus" id="contact-us-link-footer">Contact Us</NavLink>
          </span>
        </div>
        <div className="footer-copyright">
          <span>
            &copy; {currentYear} Applied Medical Resources Corporation | All rights
            reserved.
          </span>
        </div>
      </div>
    </>
  );
}

export default Footer;
