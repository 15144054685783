import React from 'react';
import { Helmet } from 'react-helmet-async';

const SEO = () => {
  const isProduction = process.env.REACT_APP_ENVIRONMENT === 'prod';
  const contentValue = isProduction ? 'index, follow' : 'noindex, nofollow';

  return (
    <Helmet>
      <meta name="robots" content={contentValue} />
    </Helmet>
  );
};

export default SEO;