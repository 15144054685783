import React from "react";
import "../../../../App.css";
import "../../../_Shared/utility.css";
import "../../../ClinicalStudiesTile/ClinicalStudiesTile";
import Banner from "../../../Banner/Banner";
import { Helmet } from "react-helmet-async";
import studies from "./Studies";
import "./ClinicalStudies.css";
import ClinicalStudiesTile from "../../../ClinicalStudiesTile/ClinicalStudiesTile";
import {cdn} from '../../../../consts/cdn';

//Images
const BannerDesktop = cdn.images + "ClinicalEvidence/Banner-Desktop.jpg";
const BannerMobile = cdn.images + "ClinicalEvidence/Banner-Mobile.jpg";

export const ClinicalStudies = () => {

  return (
    <div className="clinicalstudies">
      <Helmet>
        <title>
          vNOTES – Advanced Minimally Invasive Gynecologic Procedures
        </title>
        <link rel="canonical" href="https://www.vnotes.com/clinicalevidence" />
        <meta
          name="description"
          content="vNOTES (vaginal natural orifice transluminal endoscopic surgery) workshops provide a comprehensive understanding of procedural steps and instrumentation used…"
        />

      </Helmet>
      <Banner
        imgdesktop={BannerDesktop}
        imgmobile={BannerMobile}
        bold1="CLINICAL EVIDENCE"
        color="#365B7E"
      />
      <div className="content">
        {studies.map((study) => {
          return (
            <ClinicalStudiesTile key={study.uniqueid}
            header={study.header}
            date={study.date}
            studyurl={study.studyurl}
            boldtext={study.boldtext}
            doctors={study.doctors}
            content={study.content}
            uniqueid={study.uniqueid}
            />
          )
        })}
      </div>
    </div>
  );
};

export default ClinicalStudies;
